import React from "react"
import Layout from "../components/Layout"
// import { StaticImage } from "gatsby-plugin-image"
import SEO from "../components/SEO"
import AllProjects from "../components/AllProjects"
import { BsDownload, BsLinkedin } from "react-icons/bs"
import ActionButtons from "../components/ActionButtons"

export default function Home() {
  return (
    <Layout>
      <SEO title="Home Page" />
      <main className="page">
        <header className="hero">
          {/* <StaticImage
            src="../assets/images/main.jpeg"
            alt="eggs"
            className="hero-img"
            placeholder="tracedSVG"
            layout="fullWidth"
          /> */}
          <div className="hero-container">
            <div className="hero-text">
              <h1>
                Hi, I’m <span style={{ color: "#F87060" }}>Sophia</span>
              </h1>
              <p>
                I’m a UX/UI Designer passionate about design,
                love for detail, and exploring new places. I’m interested in
                creating more positive and meaningful experiences for everyone.
              </p>
              <ActionButtons />
            </div>
          </div>
        </header>
        <AllProjects />
      </main>
    </Layout>
  )
}
