import React from "react"
import ProjectsList from "./ProjectsList"
import { graphql, useStaticQuery } from "gatsby"

const query = graphql`
  {
    allContentfulProject(sort: {fields: projectOrder, order: ASC}) {
      nodes {
        id
        title
        coverImage {
          gatsbyImageData(layout: CONSTRAINED, placeholder: DOMINANT_COLOR)
        }
        subtitle
      }
    }
  }
`

const AllProjects = () => {
  const data = useStaticQuery(query)
  const projects = data.allContentfulProject.nodes
  return (
    <section className="recipes-container">
      <ProjectsList projects={projects} />
    </section>
  )
}

export default AllProjects
